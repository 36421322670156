// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-e-ln-u-0-n-5-oz-mk-js": () => import("./../src/pages/eLnU0n5ozMk.js" /* webpackChunkName: "component---src-pages-e-ln-u-0-n-5-oz-mk-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-z-m-5-t-3-c-r-8-w-iq-js": () => import("./../src/pages/zM5T3cR8wIQ.js" /* webpackChunkName: "component---src-pages-z-m-5-t-3-c-r-8-w-iq-js" */)
}

